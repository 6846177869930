<template>
  <v-container>
    <v-row v-if="isAdmin">
      <v-col cols="12" sm="5" md="4">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Admin Report</div>
              <v-list-item-title class="headline mb-1"
                >Orders by producers
              </v-list-item-title>
              <v-list-item-subtitle
                >Pie of daily orders by producers</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-btn to="/order/report/producers" text>view</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="5" md="4">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Admin Report</div>
              <v-list-item-title class="headline mb-1"
                >Daily Orders by producers
              </v-list-item-title>
              <v-list-item-subtitle
                >Barchart of daily orders by producers</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-btn to="/order/report/daily" text>view</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="5" md="4">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Report</div>
              <v-list-item-title class="headline mb-1"
                >Orders by customers</v-list-item-title
              >
              <v-list-item-subtitle
                >Pie Chart of daily orders by customers</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-btn to="/order/report/daily_customer" text>view</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="5" md="4">
        <v-card class="mx-auto" max-width="344" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Report</div>
              <v-list-item-title class="headline mb-1"
                >Daily Orders</v-list-item-title
              >
              <v-list-item-subtitle
                >Barchart of daily orders by customers</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-btn to="/order/report/daily_orders" text>view</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin'
    })
  }
}
</script>
